import { gql } from '@apollo/client';
import { productModel } from './models/product.model';
import { userModel } from './models/user.model';

export const subProducts = gql`
  query get_products {
    get_products(status:active) {
      count
      message
      products {
        ${productModel}
      }
    }
  }
`;
export const getImapTile = gql`
  query get_imap_tile {
    get_imap_tile {
      message
      success
      url
    }
  }
`;

export const getImapPointAddress = gql`
  query getImapPointAddress($latitude: Float!, $longitude: Float!) {
    get_imap_point_address(latitude: $latitude, longitude: $longitude) {
      message
      success
      address
    }
  }
`;

export const getImapSearchPoint = gql`
  query getImapSearchPoint(
    $startLatitude: Float!
    $startLongitude: Float!
    $endLatitude: Float!
    $endLongitude: Float!
  ) {
    get_imap_search_point(
      startLatitude: $startLatitude
      startLongitude: $startLongitude
      endLatitude: $endLatitude
      endLongitude: $endLongitude
    ) {
      message
      success
      points {
        latitude
        longitude
      }
      distance
    }
  }
`;
export const getImapSearchAddress = gql`
  query getImapSearchAddress($text: String!) {
    get_imap_search_address(text: $text) {
      locations {
        id
        name
        address
        latitude
        longitude
      }
      message
      success
    }
  }
`;

export const getActiveTrip = gql`
  query get_trip {
    get_trip {
      message
      success
      trip {
        id
        code
        totalPrice
        createdAt
        updatedAt
        cancel_user_id
        cancelUserRole
        duration
        user {
          id
        }
        driver {
          ${userModel}
        }
        vehicle {
          id
          vehicle_type_id
          vehicle_manufacture_id
          vehicle_model_id
          productionDate
          inspectionDate
          licencePlate
          certificate
          color
          rentType
          handiCapAccessibility
          isDefault
          status
          ownerType
          createdAt
          updatedAt
          created_user_id
          updated_user_id
          user_id
          vehicleType {
            id
            name
            status
            createdAt
            updatedAt
            created_user_id
            updated_user_id
          }
          subProducts {
            id
          }
          vehicleManufacture {
            id
            name
            order
            status
            createdAt
            updatedAt
            created_user_id
            updated_user_id
          }
          vehicleModel {
            id
            vehicle_type_id
            vehicle_manufacture_id
            name
            order
            createdAt
            updatedAt
            status
            created_user_id
            updated_user_id
          }
        }
        watchUser {
          id
          code
          firstname
          lastname
          email
          emailVerified
          phonePrefix
          phoneNumber
          phoneVerified
          country
          state
          timezone
          status
          statusReason
          role
          inviteCode
          avatar
          gender
          birthday
          rate
          createdAt
          updatedAt
        }
        latitude
        longitude
        endLatitude
        endLongitude
        waitTime
        jamTime
        arriveMinut
        distance
        status
        cancelReason
        processes {
          id
          latitude
          longitude
          status
          createdAt
          updatedAt
        }
        booking {
          id
          user_id
          sub_product_id
          platform
          sourceAddress
          tip
          destAddress
          sourceLatitude
          sourceLongitude
          destLatitude
          destLongitude
          canceled_user_id
          canceledAt
          canceledReason
          pre_booking_id
          notes
          type
          preDate
          isHandiCapAccessibility
          status
          subProduct {
            id
            product_id
            name
            description
            image
            marker
            artBoard
            orderBy
            status
            tips
            mainPrice
            isNewWindowAdditional
            feePercent
            bonusPercent
            createdAt
            updatedAt
          }
          additionalFields {
            name
            value
          }

          isLoad
          createdAt
          updatedAt
          radius
        }
      
      }
    }
  }
`;

export const getStrings = gql`
  query getStrings($type: STRINGS_TYPE_ENUM) {
    get_strings(type: $type) {
      count
      message
      strings {
        id
        name
        type
        createdAt
        updatedAt
      }
    }
  }
`;
