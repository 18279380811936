import { Outlet } from 'react-router-dom';

const LayoutWrap = () => {
  return (
    <div className='flex flex-wrap relative overflow-hidden'>
      <Outlet />
    </div>
  );
};

export default LayoutWrap;
