export enum SOCKET_EMIT {
  CREATE_BOOKING = 'create_booking',
  USER_CREATE_TRIP_CALLBACK = 'user_create_trip_callback',
  USER_CANCELED_BOOKING = 'user_canceled_booking',
  CANCEL_TRIP = 'cancel_trip',
  JOIN_TRIP = 'join_trip',
  TRIP_SEND_MESSAGE = 'trip_send_message'
}

export enum SOCKET_ON {
  GET_AVAILABLE_DRIVERS = 'get_available_drivers',
  USER_CREATE_BOOKING_RESPONSE = 'user_create_booking_response',
  USER_CREATE_TRIP = 'user_create_trip',
  TRACKING = 'tracking',
  TRIP_DRAW_ROAD = 'trip_draw_road',
  DRIVER_CANCEL_TRIP = 'driver_cancel_trip',
  TRIP_MESSAGE = 'trip_message',
  DRIVER_ARRIVED = 'driver_arrived'
}
