import { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import { subProducts } from 'src/graphql/queries';
import SubProductLayout from '../components/sub-product';

const SubProductContainer = () => {
  const { data, loading } = useQuery(subProducts);

  if (loading) return <div>loading</div>;
  const { get_products } = data || {};
  const { products, count } = get_products || {};

  const updatedProps = {
    products: products || [],
    count: count || 0
  };
  return (
    <Suspense fallback={<div>loading</div>}>
      <SubProductLayout {...updatedProps} />
    </Suspense>
  );
};

export default SubProductContainer;
