import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';

export const SocketContext = createContext<Socket | null>(null);

type Props = {
  children: React.ReactNode;
};

export const SocketProvider = (props: Props) => {
  const socket = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!isConnected) {
      socket.current = io('https://dev-socket.1950.mn', {
        reconnection: true,
        transports: ['websocket'],
        auth: {
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhhM2FhNzVhLTc5OGEtNGE3Ny05ZGIxLWYyZTIzOTU4YmJkOSIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwMzU3MjE2OH0.LuFtY3ydhu9AwGeRjhRfhxvWCpeY5zMeu2nbP8FZhYI',
          subProducts: []
        }
      });

      socket.current.on('connect', () => {
        console.info('connected socket');
        setIsConnected(true);
      });

      socket.current.on('error', (args: any) => {
        console.info('error', args);
      });

      socket.current.on('disconnect', () => {
        console.info('Disconnected socket');
      });
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    socket.current && (
      <SocketContext.Provider value={socket.current}>
        {props.children}
      </SocketContext.Provider>
    )
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket context ашиглаагүй байна');
  }
  return context;
};
