import { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { useSnackbar } from "notistack";
import { Map, Marker } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
//types
import { IMapConfig, LOCATION_TYPES } from "src/interfaces/map.interface";
import { useSuspenseQuery } from "@apollo/client";
import { getImapTile } from "src/graphql/queries";
import SearchLayout from "./search";
import { useSocket } from "src/contexts/socket.context";
import { SOCKET_ON } from "src/types/socket.type";
import { useProductForm } from "src/contexts/product.form.context";
import { Search } from "src/theme/icons";

//talbai
// longitude: 106.91761001507528,
// latitude: 47.91871738580771,
//re
// longitude: 106.92722659999998,
// latitude: 47.9132286,
//darhan
// longitude: 105.932160,
// latitude: 49.484017
const mapConfig: IMapConfig = {
  longitude: 105.93216,
  latitude: 49.484017,
  zoom: 13,
  maxZoom: 17,
  minZoom: 12,
};
type Props = {
  golomtToken: string;
};
const MapLayout = (props: Props) => {
  const { golomtToken } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [mapInstance, setMapInstance] = useState<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  //states
  const [locationType, setLocationType] = useState(LOCATION_TYPES.SOURCE);
  const [findDrivers, setFindDrivers] = useState<any[]>([]);
  const socket = useSocket();
  const { setIsShowProduct } = useProductForm();
  const [isNotFoundDrivers, setNotFoundDrivers] = useState(false);
  const [isSearchHidden, setIsSearchHidden] = useState(false);
  const { data }: any = useSuspenseQuery(getImapTile);

  const { get_imap_tile } = data || {};
  const { url } = get_imap_tile || {};

  const renderIcon = () => {
    return (
      document.getElementById("map_marker") || document.createElement("div")
    );
  };

  useEffect(() => {
    if (mapContainerRef?.current) {
      const initializeMap = () => {
        const map = new Map({
          container: mapContainerRef?.current || "",
          style: url,
          zoom: mapConfig.zoom,
          maxZoom: mapConfig.maxZoom,
          minZoom: mapConfig.minZoom,
          antialias: true,
          center: [mapConfig.longitude, mapConfig.latitude],
        });

        setMapInstance(map);
        return () => {
          if (map) {
            map.remove();
          }
        };
      };

      if (mapInstance) {
        mapInstance.on("load", async () => {
          mapInstance.loadImage(
            "/assets/img/light/marker_current.png",
            async (error, image) => {
              if (error) {
                console.log(error, "error load image marker_current");
                throw error;
              }
              if (!image) throw new Error("image error");
              mapInstance.addImage("marker_current", image);
            }
          );

          mapInstance.loadImage(
            "/assets/img/light/marker_destination.png",
            async (error, image) => {
              if (error) {
                console.log(error, "error load image marker_destination");
                throw error;
              }

              if (!image) throw new Error("image error");
              mapInstance.addImage("marker_destination", image);
            }
          );

          mapInstance.loadImage(
            "/assets/img/light/car.png",
            async (error, image) => {
              if (error) {
                console.log(error, "error load image car_yellow");
                throw error;
              }

              if (!image) throw new Error("image error");
              mapInstance.addImage("car_yellow", image);
            }
          );
        });
        const getPosition = new Marker({
          anchor: "center",
          element: renderIcon(),
          scale: 20,
        })
          .setLngLat([mapConfig.longitude, mapConfig.latitude])
          .addTo(mapInstance);

        mapInstance.setCenter([
          getPosition.getLngLat().lng,
          getPosition.getLngLat().lat,
        ]);

        mapInstance?.on("move", () => {
          getPosition?.setLngLat([
            mapInstance?.getCenter().lng,
            mapInstance.getCenter().lat,
          ]);
        });

        mapInstance?.on("movestart", () => {
          let markerElement = document.getElementById("marker_text");
          if (markerElement) {
            markerElement.style.transform = "scale(1.2)";
          }
        });
        mapInstance?.on("moveend", () => {
          let markerElement = document.getElementById("marker_text");
          if (markerElement) {
            markerElement.style.transform = "scale(1)";
          }
        });

        //socket
        socket?.on(SOCKET_ON.USER_CREATE_BOOKING_RESPONSE, async (data) => {
          if (!isSearchHidden) {
            setIsSearchHidden(() => true);
          }
          const { users, message }: any = data || {};
          console.log(
            "-------------------RENDER_DRIVER---------------------------"
          );
          const markerId = "taxi_drivers";
          let layerIDs: Array<string> = [];
          let driverLayerData: any = {
            type: "FeatureCollection",
            features: [],
          };

          if (mapInstance?.getSource(markerId)) {
            console.log("map_bn");
            mapInstance.getStyle().layers.forEach(async (layer) => {
              if (layer.id.startsWith("taxi-driver-")) {
                layerIDs.push(layer.id);
              }
            });
            console.log("layerIds");

            layerIDs.forEach(async (layerID: any) => {
              mapInstance.removeLayer(layerID);
            });

            mapInstance.removeSource(markerId);
          }

          if (users.length === 0) {
            setIsShowProduct(() => true);
            enqueueSnackbar(message, {
              variant: "success",
            });
            const element = document.getElementById("map_marker");
            if (element) element.style.display = "flex";
            console.log("Жолооч олдсонгүй");
            setNotFoundDrivers(true);
            return setIsSearchHidden(() => false);
          } else {
            const element = document.getElementById("map_marker");
            if (element) element.style.display = "none";
          }
          setFindDrivers(users);

          for await (let driverUser of users) {
            const coordinate = [driverUser.longitude, driverUser.latitude];
            console.log(coordinate, "coordinate");
            driverLayerData.features.push({
              type: "Feature",
              properties: {
                icon: `${driverUser.id}`,
                rotate: driverUser.rotate,
              },
              geometry: {
                type: "Point",
                coordinates: coordinate,
              },
            });
          }

          mapInstance?.addSource(markerId, {
            type: "geojson",
            data: driverLayerData,
          });

          for (let feature of driverLayerData.features) {
            const symbol = feature.properties["icon"];
            const layerID = `taxi-driver-${symbol}`;

            mapInstance?.addLayer({
              id: layerID,
              type: "symbol",
              source: markerId,
              layout: {
                "icon-size": 0.4,
                "icon-image": "car_yellow",
              },
            });
          }

          const nowDriver = users.filter((user: any) => user.isNow === true);
          if (nowDriver.length) {
            console.log(
              [nowDriver[0].longitude, nowDriver[0].latitude],
              "nowDriver"
            );
            mapInstance?.setCenter([
              nowDriver[0].longitude,
              nowDriver[0].latitude,
            ]);
          }

          console.log(
            "-----------------------END------------------------------"
          );
        });

        socket?.on(SOCKET_ON.GET_AVAILABLE_DRIVERS, async (data) => {
          console.log(data, "data");
        });

        socket?.on(SOCKET_ON.USER_CREATE_TRIP, () => {
          console.log("USER_CREATE_TRIP-------------------------------------");
          window.location.href = `/trip?token=${golomtToken}`;
        });
      } else {
        initializeMap();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, url, socket]);

  const renderSearchLayout = useMemo(
    () =>
      mapInstance && (
        <SearchLayout
          mapInstance={mapInstance}
          locationType={locationType}
          setLocationType={setLocationType}
          isSearchHidden={isSearchHidden}
          // setSearchType={setSearchType}
          // searchType={searchType}
        />
      ),
    [locationType, mapInstance, isSearchHidden]
  );

  const renderInnerDrivers = (
    <div className="fixed bottom-0 w-full bg-black px-3 py-4 flex-column z-30">
      <div className="flex justify-between items-center gap-4">
        <Search className="stroke-white/80" />
        <h4 className="text-white font-semibold text-lg leading-6">
          Танд ойрхон таксиг дуудаж байна та түр хүлээнэ үү
        </h4>
      </div>
      <div className="flex flex-nowrap justify-between items-center bg-zinc-800/85 py-1 px-4 mt-6 mb-4 rounded-lg">
        {/* sub_product item 1 start */}
        <div className="flex items-center">
          <div className="w-[64px] mr-2">
            <img
              src="https://1950.sgp1.cdn.digitaloceanspaces.com/sub_product/12444520e2c09feff6979ba628ea5f56af188574"
              alt="sib"
            />
          </div>
          <div className="">
            <p className="font-semibold text-white">Standart</p>
            <span className="text-sm text-white/55 font-medium">
              1-р эгнээгээр зорчдог
            </span>
          </div>
        </div>
        {/* sub_prduct item 1 end */}
        <div className="text-white">
          {findDrivers.findIndex((driver: any) => driver.isNow) + 1}/
          {findDrivers.length}
        </div>
      </div>
      <div className="bg-white w-full p-3 rounded-2xl text-center mb-2">
        <button className="text-red-700 font-bold text-md">Цуцлах</button>
      </div>
    </div>
  );

  const renderDriverNotFound = (
    <div className="fixed bottom-0 bg-black py-4 px-5 w-full flex-col justify-center z-30">
      <div className="item-center text-center mb-6 flex-col">
        <img
          src="https://1950.sgp1.cdn.digitaloceanspaces.com/sub_product/12444520e2c09feff6979ba628ea5f56af188574"
          alt="car"
          className="w-32 m-auto object-cover h-24"
        />
        <h4 className="text-white/85 text-lg leading-6">
          Одоогоор бүх жолооч дуудлагатай байна, хэсэг хүлээгээд дахин оролдоно
          уу
        </h4>
      </div>
      <button
        className="rounded-2xl w-full font-bold text-lg bg-zinc-800 text-white p-2 mb-3"
        onClick={() => setNotFoundDrivers(false)}
      >
        Болих
      </button>
    </div>
  );

  return (
    <Fragment>
      {!isSearchHidden && renderSearchLayout}
      <div ref={mapContainerRef} className="h-[100vh] w-full map z-[1]" />
      <div
        className="flex-col items-center transition-all ease-in-out"
        id="map_marker"
        style={{
          display: "flex",
        }}
      >
        <span
          className="bg-black rounded-xl text-white py-2 px-3 leading-none font-medium"
          id="marker_text"
        >
          {locationType === LOCATION_TYPES.SOURCE
            ? "Миний байршил"
            : "Очиж авах"}
        </span>
        <div className="w-[2px] h-5 bg-black" />
        <div className="w-3 h-[3px] bg-black mt-[2px] rounded-sm" />
      </div>
      {isSearchHidden && renderInnerDrivers}
      {isNotFoundDrivers && renderDriverNotFound}
    </Fragment>
  );
};

export default MapLayout;
