import { Fragment, useEffect, useRef, useState } from 'react';
import { Switch } from '@headlessui/react';
import { IProduct } from 'src/interfaces/product.interface';
import {
  FIELD_TYPE,
  ISubProduct,
  ISubProductField
} from 'src/interfaces/sub_product.interface';
import { numberFormatter } from 'src/utils/fomatter';
import { ChevronUp, MapPin } from 'src/theme/icons';
import { useProductForm } from 'src/contexts/product.form.context';
import { useSocket } from 'src/contexts/socket.context';
import { useForm } from 'react-hook-form';
import { SOCKET_EMIT } from 'src/types/socket.type';
import { useMutation } from '@apollo/client';
import { createBooking } from 'src/graphql/mutations';
import DialogComponent from 'src/modules/commons/dialog';

type Props = {
  count: number;
  products: Array<IProduct>;
};

const SubProductWrap = (props: Props) => {
  const { products } = props;
  console.log(products, 'products');
  const formWrapRef = useRef<any>(null);
  const subProductRef = useRef<HTMLDivElement | null>(null);
  const { sourceData, destData } = useProductForm();
  const [isShow, setIsShow] = useState(false);
  const [isPeakLoad, setPeakLoad] = useState(false); //Оргил ачаалал dialog
  const [subProduct, setSubProduct] = useState<ISubProduct | null>(null);
  const [isForm, setIsForm] = useState(false);
  const [driverTip, setDriverTip] = useState(0);
  const [isHandiCap, setisHandiCap] = useState(false);
  const socket = useSocket();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const [createBookingMutation, { loading }] = useMutation(createBooking, {
    onCompleted(data) {
      const { create_booking } = data || {};
      const { success, message, booking_id } = create_booking || {};
      if (!success) {
        return alert(message);
      }

      socket.emit(SOCKET_EMIT.CREATE_BOOKING, { booking_id, isAgain: false });
      console.log(booking_id, 'booking_id');
      setIsForm(() => false);
      setIsShow(() => false);
      // setIsProductShow(false);
      reset();
    }
  });

  useEffect(() => {
    console.log('SUB_PRODUCT');
    socket.on('user_create_booking_response', (args) => {
      console.log('user_create_booking_response_args', args);
    });
  }, [socket, sourceData?.sourceAddress, destData?.destAddress]);

  const onMenu = () => {
    setIsShow((prev) => !prev);
  };

  const onChangeSubProduct = (subProduct: ISubProduct | null) => {
    setSubProduct(subProduct);
    if (formWrapRef) {
      formWrapRef.current.scrollTo(0, 0);
    }
    setIsForm(true);
    setIsShow(false);
  };

  const onCloseSubProduct = () => {
    setIsForm(false);
    setIsShow(false);
    reset();

    const timer = setTimeout(() => {
      setSubProduct(null);
    }, 300);

    window.clearTimeout(timer);
  };

  const onDriverTip = (
    e: React.ChangeEvent<HTMLInputElement>,
    tips: Array<number>
  ) => {
    const { target } = e;
    const { value } = target;
    const eventValue = Number(value);
    if (eventValue === 0) return setDriverTip(0);

    console.log(value, 'value');
    for (let tip of tips) {
      if (tip === eventValue || eventValue < tip) {
        setDriverTip(tip);
        break;
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    console.log(data, 'data');
    console.log(JSON.stringify(data), 'data');

    let doc = {
      ...data,
      sub_product_id: subProduct?.id,
      tip: driverTip,
      isHandiCapAccessibility: false,
      notes: ['Тест хийж байна'],
      ...sourceData,
      ...destData
    };
    await createBookingMutation({
      variables: doc
    });
  });

  const formAutoClick = (isClick: boolean) => {
    setPeakLoad(isClick);
    if (isClick) {
      onSubmit();
      setPeakLoad(false);
    }
  };

  const renderDriverInformation = (
    <div className='flex flex-col flex-wrap text-white'>
      <h6 className='leading-6 my-2'>Үнийн мэдээлэл</h6>
      {(subProduct?.prices || []).map((price: any) => (
        <div className='flex justify-between leading-7' key={price.id}>
          <span>{price?.name || ''}</span>
          <span>{numberFormatter(price?.value)}₮</span>
        </div>
      ))}
    </div>
  );

  const renderInputText = (additional: ISubProductField, index: number) => {
    return (
      <div>
        <input
          type='hidden'
          {...register(`additionalFields[${index}].id`, {
            value: additional.id
          })}
        />
        <input
          type='text'
          {...register(`additionalFields[${index}].value`, {
            required: additional.isRequired || false
          })}
          id={`${additional.artBoard}_${additional.id}`}
          className='block w-full rounded-xl border-0 py-2 text-white placeholder-white bg-zinc-800 ring-[0.5px] ring-white focus:ring-white mb-2'
        />
        {errors[`${additional.id}`] &&
          errors[`${additional.id}`]?.message &&
          `${errors[`${additional.id}`]?.message}`}
      </div>
    );
  };

  const renderOption = (additional: ISubProductField, index: number) => {
    return (
      <div>
        <input
          type='hidden'
          {...register(`additionalFields[${index}].id`, {
            value: additional.id
          })}
        />
        <select
          {...register(`additionalFields[${index}].value`, {
            required: additional.isRequired,
            value: additional.options[0]
          })}
          className='block w-full rounded-xl border-0 py-2 ring-[0.5px] bg-zinc-800 ring-white focus:ring-white text-white mb-2'
        >
          {(additional.options || []).map((option: string) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        {errors[`${additional.id}`] && `${errors[`${additional.id}`]?.message}`}
      </div>
    );
  };

  const renderProductForm = (
    <div
      ref={formWrapRef}
      className={`absolute m-0 grid grid-cols-1 px-4 pt-9 mb-20 bg-black w-full text-white rounded-t-[24px] transition-[bottom] ease-in-out duration-300 z-40 h-3/5 overflow-y-scroll`}
      style={{
        bottom: isForm ? 0 : '-999px'
      }}
    >
      <form onSubmit={onSubmit}>
        <div className='flex flex-nowrap justify-between items-center bg-zinc-700 py-1 px-4 mb-2 rounded-lg'>
          {/* sub_product item 1 start */}
          <div className='flex items-center'>
            <div className='w-[64px] mr-2'>
              <img src={subProduct?.image} alt={subProduct?.name || ''} />
            </div>
            <div className=''>
              <p className='font-semibold'>{subProduct?.name || ''}</p>
              <span className='text-sm'>{subProduct?.description}</span>
            </div>
          </div>
          {/* sub_prduct item 1 end */}
          <div>{numberFormatter(subProduct?.mainPrice || 0)}₮</div>
        </div>
        <div className='mb-2'>
          <label
            htmlFor='pickup_location'
            className='block text-sm  leading-6 text-white'
          >
            Очиж авах хаяг
          </label>
          <div className='relative mt-2 rounded-md shadow-sm'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MapPin className='stroke-white w-5 h-5' />
            </div>
            <input
              type='text'
              name='source_address'
              id='source_address'
              className='block w-full rounded-xl border-0 py-2 pl-10 text-white placeholder-white bg-zinc-800 ring-[0.5px]  ring-white focus:ring-white'
              readOnly
              defaultValue={sourceData?.sourceAddress || ''}
            />
          </div>
        </div>
        {destData && destData.destAddress && (
          <div className='mb-2'>
            <label
              htmlFor='pickup_location'
              className='block text-sm  leading-6 text-white'
            >
              Хүргэх хаяг
            </label>
            <div className='relative mt-2 rounded-md shadow-sm'>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <MapPin className='stroke-white w-5 h-5' />
              </div>
              <input
                type='text'
                name='dest_address'
                id='dest_address'
                className='block w-full rounded-xl border-0 py-2 pl-10 text-white placeholder-white bg-zinc-800 ring-[0.5px]  ring-white focus:ring-white'
                readOnly
                value={destData.destAddress || ''}
                placeholder=''
              />
            </div>
          </div>
        )}
        {/** additional_fields_start */}
        {(subProduct?.additional_fields || []).map(
          (additional: ISubProductField, index: number) => (
            <div key={additional.id} className='flex flex-col mb-2'>
              <label
                htmlFor={additional.artBoard || ''}
                className='block text-sm leading-6 text-white'
              >
                {additional.name || ''}
              </label>
              <div className='relative mt-2 rounded-md shadow-sm'>
                {additional.dataType === FIELD_TYPE.TEXT &&
                  renderInputText(additional, index)}
                {additional.dataType === FIELD_TYPE.OPTIONS &&
                  renderOption(additional, index)}
              </div>
            </div>
          )
        )}
        {/** addtional_fields_end */}
        {/** tips_start */}
        {subProduct?.tips?.length && (
          <div className='rounded-xl block w-full border-[0.5px] p-2 my-2'>
            <div className='flex flex-col flex-wrap mb-2'>
              <label
                htmlFor='driver_tip'
                className='block text-sm leading-6 text-white'
              >
                Жолоочийн урамшуулал
              </label>

              <input
                type='range'
                list='driver_tip'
                value={driverTip}
                min={0}
                onChange={(e) => onDriverTip(e, subProduct.tips)}
                max={subProduct?.tips[subProduct.tips.length - 1] || 0}
                className='w-full bg-gray-200 dark:bg-gray-700 range-sm'
              />
              <datalist id='driver_tip'>
                <option value={0} />
                {(subProduct?.tips || []).map((tip: number) => (
                  <option value={tip} key={`tip_${tip}`} />
                ))}
              </datalist>
            </div>
            {/** driver_information_start */}
            {subProduct?.prices?.length && renderDriverInformation}
            {/** driver_information_send */}
          </div>
        )}
        {/** tips_end */}
        {/** handicap start */}
        <div className='relative flex gap-x-3 my-4'>
          <Switch.Group as='div' className='flex items-center'>
            <Switch
              onChange={() => setisHandiCap((prev) => !prev)}
              name='isHandiCapAccessibility'
              className={`${
                isHandiCap ? 'bg-green-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2`}
              checked={true}
            >
              <span
                aria-hidden='true'
                className={` ${
                  isHandiCap ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <Switch.Label as='span' className='ml-3 text-sm '>
              <span className='font-medium text-white'>
                Хөгжлийн бэрхшээлтэй
              </span>
            </Switch.Label>
          </Switch.Group>
        </div>

        {/** handicap end */}
        <div
          className='mt-2 flex gap-3 p-4 bg-black bottom-0 w-full left-0'
          style={{
            position: isForm ? 'fixed' : 'relative'
          }}
        >
          <button
            type='reset'
            className='rounded-full border-slate-500 border relative w-14 flex justify-center items-center'
            onClick={() => onCloseSubProduct()}
          >
            <ChevronUp className='stroke-white -rotate-90' />
          </button>
          <button
            className='bg-white text-black p-3 rounded-2xl font-medium w-full'
            type='button'
            onClick={() => setPeakLoad(true)}
            disabled={isPeakLoad || loading}
          >
            Унаа дуудах
          </button>
        </div>
      </form>
    </div>
  );

  const renderBounce = (
    <span
      className=' absolute right-0 left-0 -top-3 p-4  mx-auto inline-block transition-all delay-100 bg-black rounded-full w-8 h-8 shadow-transparent'
      onClick={() => onMenu()}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className={`w-5 h-5 absolute left-[6px] top-[3px] right-0 ${
          isShow && 'rotate-180'
        }`}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='m4.5 18.75 7.5-7.5 7.5 7.5'
        />
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='m4.5 12.75 7.5-7.5 7.5 7.5'
        />
      </svg>
    </span>
  );

  return (
    <Fragment>
      <div
        className={`fixed  grid-cols-1 p-4 bg-black w-full text-white rounded-t-[24px] transition-all  ease-in-out duration-500`}
        ref={subProductRef}
        style={{
          zIndex: 30,
          bottom: `${
            isShow
              ? 0
              : subProductRef.current?.clientHeight
              ? `-${subProductRef.current?.clientHeight * 0.66}px`
              : `-50%`
          }`
        }}
      >
        {renderBounce}
        {(products || []).map((product: IProduct) => (
          <div key={product.id} className='px-2'>
            <h5 className='text-lg font-semibold mb-3'> {product.name}</h5>
            {(product?.sub_products || []).map((subProduct: ISubProduct) => (
              <div
                key={subProduct?.id}
                className='flex flex-nowrap justify-between items-center bg-zinc-700 py-1 px-4 mb-2 rounded-lg'
                onClick={() => onChangeSubProduct(subProduct)}
              >
                {/* sub_product item 1 start */}
                <div className='flex items-center'>
                  <div className='w-[64px] mr-2'>
                    <img src={subProduct?.image} alt={subProduct?.name || ''} />
                  </div>
                  <div className=''>
                    <p className='font-semibold'>{subProduct?.name || ''}</p>
                    <span className='text-sm'>{subProduct?.description}</span>
                  </div>
                </div>
                {/* sub_prduct item 1 end */}
                <div>{numberFormatter(subProduct?.mainPrice || 0)}₮</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {renderProductForm}

      <DialogComponent
        isShow={isPeakLoad}
        setIsShow={setPeakLoad}
        formAutoClick={formAutoClick}
      />
    </Fragment>
  );
};

export default SubProductWrap;
