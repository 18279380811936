export const userModel = `
  id
  code
  firstname
  lastname
  email
  emailVerified
  phonePrefix
  phoneNumber
  phoneVerified
  country
  state
  timezone
  status
  statusReason
  role
  inviteCode
  avatar
  gender
  birthday
  rate
  createdAt
  updatedAt
`;
