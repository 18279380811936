import { createContext, useContext, useState } from 'react';
import { IProductDest, IProductSource } from './product-context.interface';

type TProductFormContext = {
  sourceData: IProductSource | null;
  setSourceData: React.Dispatch<React.SetStateAction<IProductSource | null>>;
  destData: IProductDest | null;
  setDestData: React.Dispatch<React.SetStateAction<IProductDest | null>>;
  isShowProduct: boolean;
  setIsShowProduct: React.Dispatch<React.SetStateAction<boolean>>;
  setDistnict: React.Dispatch<React.SetStateAction<number>>;
  distnict: number;
};

export const ProductFormContext = createContext<TProductFormContext | null>(
  null
);

type Props = {
  children: React.ReactNode;
};

export const ProductFormProvider = (props: Props) => {
  const [sourceData, setSourceData] = useState<IProductSource | null>(null);
  const [destData, setDestData] = useState<IProductDest | null>(null);
  const [distnict, setDistnict] = useState(0);
  const [isShowProduct, setIsShowProduct] = useState(true);
  return (
    <ProductFormContext.Provider
      value={{
        sourceData,
        setSourceData,
        destData,
        setDestData,
        isShowProduct,
        setIsShowProduct,
        setDistnict,
        distnict
      }}
    >
      {props.children}
    </ProductFormContext.Provider>
  );
};

export const useProductForm = () => {
  const context = useContext(ProductFormContext);
  if (!context) {
    throw new Error('useProductForm context ашиглаагүй байна');
  }
  return context;
};
