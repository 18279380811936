import { STATUS } from './common.interface';

export enum PRICE_TYPE {
  KM_PRICE = 'km_price',
  TRAFFIC_PRICE = 'traffic_price',
  CALL_PRICE = 'call_price',
  WAIT_PRICE = 'wait_price',
  FEE_PRICE = 'fee_price',
  DURATION_PRICE = 'duration_price',
  LOAD_PRICE = 'load_price'
}

export enum PRICE_TYPE_FILTER {
  ALL = 'all',
  KM_PRICE = 'km_price',
  TRAFFIC_PRICE = 'traffic_price',
  CALL_PRICE = 'call_price',
  WAIT_PRICE = 'wait_price',
  FEE_PRICE = 'fee_price',
  DURATION_PRICE = 'duration_price',
  LOAD_PRICE = 'load_price'
}

export enum FIELD_TYPE {
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  OPTIONS = 'options'
}

export interface ISubProduct {
  id: string;
  name: string;
  description: string;
  image: string;
  feePercent: number;
  bonusPercent: number;
  orderBy: number;
  isNewWindowAdditional: boolean;
  product_id: string;
  marker: string;
  artBoard: string;
  additional_fields: [ISubProductField];
  tips: [number];
  prices: [ISubProductPrice];
  mainPrice: number;
  status: STATUS;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISubProductForm {
  id: string;
  name: string;
  description: string;
  image: string;
  marker: string;
  artBoard: string;
  feePercent: number;
  bonusPercent: number;
  orderBy: number;
  mainPrice: number;
  isNewWindowAdditional: boolean;
}

export interface ISubProductPrice {
  id: string;
  name: string;
  value: number;
  type: PRICE_TYPE;
  sub_product_id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISubProductPriceForm {
  id: string;
  name: string;
  value: number;
  type: PRICE_TYPE;
  sub_product_id: string;
}

export interface ISubProductField {
  id: string;
  name: string;
  sub_product_id: string;
  dataType: FIELD_TYPE;
  options: string[];
  isRequired: boolean;
  orderBy: number;
  createdAt: Date;
  updatedAt: Date;
  artBoard: string;
}

export interface ISubProductFieldForm {
  id: string;
  name: string;
  sub_product_id: string;
  dataType: FIELD_TYPE;
  options: string[];
  isRequired: boolean;
  orderBy: number;
}
