import { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Map } from 'maplibre-gl';
//apollo
import {
  getImapPointAddress,
  getImapSearchAddress,
  getImapSearchPoint
} from 'src/graphql/queries';
//context
import { useProductForm } from 'src/contexts/product.form.context';
//types
import {
  IMapSearchAddress,
  LOCATION_TYPES,
  SEARCH_TYPE
} from 'src/interfaces/map.interface';
//components
import { ArrowLeft, MapPin, Pin, Search } from 'src/theme/icons';
import { useDebounce } from 'src/modules/commons/debounce';
import useAsyncState from 'src/modules/commons/sync-state';

import SubProduct from 'src/modules/product/containers/sub-product';

type Props = {
  mapInstance: Map;
  setLocationType: Function;
  locationType: LOCATION_TYPES;
  isSearchHidden: boolean;
};

let searchType: SEARCH_TYPE = SEARCH_TYPE.YES;

const SearchLayout = (props: Props) => {
  const { mapInstance, setLocationType, locationType, isSearchHidden } = props;

  const { setDestData, setSourceData, setDistnict } = useProductForm();
  const [isListOpenLayout, setListOpenLayout] = useAsyncState(false);
  const [isProductShow, setIsProductShow] = useState(true); //subproduct show hidden
  const [sourceSearchValue, setSourceSearchValue] = useAsyncState('');
  const [destSearchValue, setDestSearchValue] = useAsyncState('');
  const [sourceLat, setSourceLat] = useAsyncState(0);
  const [sourceLng, setSourceLng] = useAsyncState(0);
  const [destLat, setDestLat] = useAsyncState(0);
  const [destLng, setDestLng] = useAsyncState(0);
  const [isMapActionOpen, setIsMapActionOpen] = useAsyncState(false);

  const [searchLocation, setSearchLocation] = useState<
    Array<IMapSearchAddress>
  >([]);
  const [qSearch, setqSearch] = useAsyncState('');

  const drawLayer = async (
    slat: number,
    slong: number,
    dlat: number,
    dlong: number
  ) => {
    searchType = SEARCH_TYPE.NO;
    const { data }: any = await getSearchPoint({
      variables: {
        startLatitude: slat,
        startLongitude: slong,
        endLatitude: dlat,
        endLongitude: dlong
      }
    });

    const { get_imap_search_point } = data || {};
    const { success, message, points, distance } = get_imap_search_point || {};
    console.log(performance.now(), 'performance.now()');
    if (!success) {
      return alert(message);
    }

    setDistnict(() => distance);

    let taxiPoints: any = {
      type: 'FeatureCollection',
      features: []
    };

    const id = 'taxi_lines';

    if (mapInstance.getLayer(id)) {
      mapInstance.removeLayer(id);
    }

    if (mapInstance.getSource(id)) {
      mapInstance.removeSource(id);
    }
    let coordinates: any = [];

    (points || []).map((point: any) => {
      const coordinate = [point.longitude, point.latitude];
      taxiPoints.features.push({
        type: 'Feature',
        properties: {
          icon: `${point.longitude}_${point.latitude}`
        },
        geometry: {
          type: 'Point',
          coordinates: coordinate
        }
      });

      return coordinates.push(coordinate);
    });

    mapInstance.addSource(id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates
        }
      }
    });

    mapInstance.addLayer({
      id,
      type: 'line',
      source: id,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'blue',
        'line-width': 3
      }
    });

    await twoPositionLine(
      [points[0]?.longitude || slong, points[0]?.latitude || slat],
      [
        points[points.length - 1]?.longitude || dlong,
        points[points.length - 1]?.latitude || dlat
      ]
    );
  };

  const twoPositionLine = async (startPosition: any, endPosition: any) => {
    const layerIds = [
      {
        id: 'layer_pick',
        coordinate: startPosition,
        image: 'marker_destination'
      },
      {
        id: 'layer_give',
        coordinate: endPosition,
        image: 'marker_current'
      }
    ];

    for await (let layer of layerIds) {
      if (mapInstance.getSource(layer.id)) {
        mapInstance.getStyle().layers.forEach(async (sLayer) => {
          if (sLayer.id.startsWith(`${layer.id}_id`)) {
            mapInstance.removeLayer(sLayer.id);
          }
        });

        mapInstance.removeSource(layer.id); //layer remove
      }

      //addSource
      mapInstance.addSource(layer.id, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                icon: layer.image
              },
              geometry: {
                type: 'Point',
                coordinates: layer.coordinate
              }
            }
          ]
        }
      });
      //
      if (!mapInstance.getLayer(`${layer.id}_id`)) {
        mapInstance.addLayer({
          id: `${layer.id}_id`,
          type: 'symbol',
          source: layer.id,
          minzoom: 12,
          maxzoom: 17.5,
          layout: {
            'icon-image': `${layer.image}`,
            'icon-size': 0.3,
            'icon-allow-overlap': true,
            'icon-overlap': 'cooperative'
          }
        });
      } else {
        console.log('layer_bn');
      }
      console.log('-------------------------------------');
    }
  };

  const [getSearchPoint] = useLazyQuery(getImapSearchPoint);

  const [pointAddress, { loading }] = useLazyQuery(getImapPointAddress, {
    onCompleted(data) {
      const { get_imap_point_address } = data || {};
      const { address, success, message } = get_imap_point_address || {};
      if (!success) {
        return alert(`${message} getImapPointAddress`);
      }

      // posistionSourceRemoves();
      if (locationType === LOCATION_TYPES.SOURCE) {
        setSourceSearchValue(address);
        setSourceLat(mapInstance.getCenter().lat);
        setSourceLng(mapInstance.getCenter().lng);
        setSourceData(() => {
          return {
            sourceAddress: address,
            sourceLatitude: mapInstance.getCenter().lat,
            sourceLongitude: mapInstance.getCenter().lng
          };
        });
      } else {
        setDestSearchValue(address);
        setDestLat(mapInstance.getCenter().lat);
        setDestLng(mapInstance.getCenter().lng);
        setDestData(() => {
          return {
            destAddress: address,
            destLatitude: mapInstance.getCenter().lat,
            destLongitude: mapInstance.getCenter().lng
          };
        });
      }
    }
  });

  const [searchAddress, { loading: imapSearchLoading }] = useLazyQuery(
    getImapSearchAddress,
    {
      onCompleted(data) {
        const { get_imap_search_address } = data || {};
        const { message, success, locations } = get_imap_search_address || {};
        if (!success) {
          return alert(`${message} get_imap_search_address`);
        }
        setSearchLocation(() => locations);
      }
    }
  );

  const onDebounceSearchQuery = useDebounce(searchAddress, 200);

  useEffect(() => {
    mapInstance.on('moveend', async () => {
      if (searchType === SEARCH_TYPE.YES) {
        await pointAddress({
          variables: {
            latitude: mapInstance.getCenter().lat,
            longitude: mapInstance.getCenter().lng
          }
        });
      }
    });
  });

  const onSetLocation = async (location: IMapSearchAddress) => {
    if (LOCATION_TYPES.SOURCE === locationType) {
      setSourceSearchValue(location.address);
      setSourceLat(location.latitude);
      setSourceLng(location.longitude);
      //
      if (destLat && destLng) {
        searchType = SEARCH_TYPE.NO;

        const element = document.getElementById('map_marker');
        if (element) element.style.display = 'none';
        drawLayer(location.latitude, location.longitude, destLat, destLng);
      }
    } else {
      //
      if (sourceLat && sourceLng) {
        searchType = SEARCH_TYPE.NO;

        const element = document.getElementById('map_marker');
        if (element) element.style.display = 'none';
        drawLayer(sourceLat, sourceLng, location.latitude, location.longitude);
      }

      setDestSearchValue(location.address);
      setDestLat(location.latitude);
      setDestLng(location.longitude);
    }
    mapInstance.setCenter([location.longitude, location.latitude]);

    onCloseSearch();
  };

  const onCloseSearch = async () => {
    setListOpenLayout(false);
    setIsProductShow(true); //subproduct харуулах

    setSearchLocation(() => []);
    // setIsShowProduct(() => true);
    setqSearch('');
    searchType = SEARCH_TYPE.NO;
  };

  const onMapActionDialog = async () => {
    setListOpenLayout(false);
    setIsMapActionOpen(true);
    setIsProductShow(false);
    // setIsShowProduct(() => false);
    searchType = SEARCH_TYPE.YES;

    const element = document.getElementById('map_marker');
    if (element) element.style.display = 'flex';
  };

  /**
   *  Байршил дээрийн хайлт харуулах
   * @param type LOCALTION_TYPES
   */
  const onShowLayout = (type: LOCATION_TYPES) => {
    setLocationType(type);
    setIsProductShow(false);

    setListOpenLayout(true); //хайлт харуулах
    setqSearch(
      LOCATION_TYPES.SOURCE === type ? sourceSearchValue : destSearchValue
    );
  };

  /**
   *  Газрын зурагнаас сонгох function
   */
  const onLocationChoose = async () => {
    setIsMapActionOpen(false);
    setIsProductShow(true); //subproduct нуух

    if (locationType === LOCATION_TYPES.DEST) {
      searchType = SEARCH_TYPE.NO;

      const element = document.getElementById('map_marker');
      if (element) element.style.display = 'none';
      if (sourceLat && sourceLng) {
        drawLayer(
          sourceLat,
          sourceLng,
          mapInstance.getCenter().lat,
          mapInstance.getCenter().lng
        );
      }
    } else {
      if (destLat && destLng) {
        searchType = SEARCH_TYPE.NO;

        const element = document.getElementById('map_marker');
        if (element) element.style.display = 'none';

        drawLayer(
          mapInstance.getCenter().lat,
          mapInstance.getCenter().lng,
          destLat,
          destLng
        );
      }
    }
  };

  /**
   * //байршилын хайлтын func
   * @param e InputEvent
   */
  const onLocationAddressSearch = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = e;
    const { value } = target;
    setqSearch(value);

    if (value?.trim().length < 1) {
      if (locationType === LOCATION_TYPES.SOURCE) {
        setSourceSearchValue(() => '');
        setSourceData(() => {
          return {
            sourceAddress: '',
            sourceLatitude: 0,
            sourceLongitude: 0
          };
        });
      } else {
        setDestSearchValue(() => '');
        setDestData(() => {
          return {
            destAddress: '',
            destLatitude: 0,
            destLongitude: 0
          };
        });
      }

      return setSearchLocation(() => []);
    }

    onDebounceSearchQuery({
      variables: {
        text: value
      }
    });
  };

  const renderLoading = (top?: string) => {
    return (
      <div role='status' className={`absolute right-1 ${top ? top : 'top-2'}`}>
        <svg
          aria-hidden='true'
          className='w-6 h-6 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-black'
          viewBox='0 0 100 101'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
            fill='currentColor'
          />
          <path
            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
            fill='currentFill'
          />
        </svg>
        <span className='sr-only'>Уншиж байна...</span>
      </div>
    );
  };

  //Хайлтын layout хэсэг
  const renderSearchLayout = (
    <div className='fixed inset-0 z-40 w-screen overflow-y-auto transition-all ease-in-out delay-200'>
      <div className='relative mx-auto w-full h-screen transformoverflow-hidden bg-black shadow-2xl ring-1 ring-black ring-opacity-5 transition-all py-2'>
        <div className='relative flex items-center m-2'>
          <div
            className='mr-3 rounded-full bg-zinc-700 h-12 w-14 flex items-center justify-center cursor-pointer'
            onClick={() => onCloseSearch()}
          >
            <ArrowLeft className='stroke-white' />
          </div>
          <div className='flex items-center rounded-2xl border-gray-400 border px-2 w-full'>
            <MapPin className='pointer-events-none h-5 w-5 stroke-white' />
            <input
              type='search'
              className='h-12 w-full border-0 bg-transparent text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm'
              placeholder={`${
                LOCATION_TYPES.SOURCE === locationType ? 'Өөрийн' : 'Очих'
              } хаяг аа оруулах`}
              onChange={(event) => onLocationAddressSearch(event)}
              value={qSearch}
            />
            {imapSearchLoading && renderLoading('top-3')}
          </div>
        </div>

        {searchLocation.length > 0 ? (
          <div className='max-h-[80vh] transform-gpu scroll-py-3 overflow-y-auto p-3'>
            {searchLocation.map((location: IMapSearchAddress) => (
              <div
                key={`${location?.latitude}_${location.longitude}_${location.address}`}
                className='flex cursor-default select-none px-2 py-3 mb-2 items-center border-b'
                onClick={() => onSetLocation(location)}
              >
                <div className='flex h-4 w-4 flex-none items-center justify-center mr-2'>
                  <Pin className='stroke-white' />
                </div>
                <div className=''>
                  <p className='text-md text-white'>{location.address}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='px-6  absolute top-1/2  transform -translate-y-1/2 w-full'>
            <Search className='mx-auto w-14 h-14 stroke-zinc-400 stroke-1' />
            <h3 className='mt-3 text-lg text-zinc-400 text-center'>
              Одоогоор хадгалсан хаяг байхгүй байна
            </h3>
          </div>
        )}

        <div
          className='transition-all ease-out delay-200 justify-center items-center bg-zinc-800 py-5 bottom-0 fixed z-40 w-full flex'
          onClick={() => onMapActionDialog()}
        >
          <Pin className='stroke-white mr-2' />
          <span className='text-white text-md font-light'>
            Газрын зурагнаас сонгох
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className='absolute top-2 z-20 left-0 right-0'>
        {/** миний байршил wrap */}
        <div className='relative flex items-center bg-black p-2 rounded-3xl mx-4'>
          <div className='flex'>
            <label
              htmlFor='source_search'
              className='text-xs font-medium text-white text-justify pl-4'
            >
              миний байршил
            </label>
          </div>
          <div className='block w-full relative'>
            <input
              type='text'
              name='source_search'
              readOnly
              disabled={loading || isMapActionOpen}
              defaultValue={sourceSearchValue}
              onClick={() => onShowLayout(LOCATION_TYPES.SOURCE)}
              id='source_search'
              className='w-full rounded-3xl h-auto'
              placeholder='Миний байгаа баршил'
            />
            {loading &&
              locationType === LOCATION_TYPES.SOURCE &&
              renderLoading()}
          </div>
        </div>
        {/** Хүргүүлэх хаяг wrap */}
        <div className='relative flex items-center bg-black p-2 rounded-3xl mx-4 mt-3'>
          <div className='flex'>
            <label
              htmlFor='dest_search'
              className='text-xs font-medium text-white pl-4 text-left'
            >
              Хүргүүлэх хаяг
            </label>
          </div>
          <div className='block w-full relative'>
            <input
              type='text'
              name='dest_search'
              disabled={loading || isMapActionOpen}
              defaultValue={destSearchValue}
              onClick={() => onShowLayout(LOCATION_TYPES.DEST)}
              readOnly
              id='dest_search'
              className='w-full rounded-3xl h-auto'
              placeholder='Хүргүүлэх хаяг аа сонгоно уу'
            />
            {loading && locationType === LOCATION_TYPES.DEST && renderLoading()}
          </div>
        </div>
      </div>
      {/** тект байршилын хайлт*/}
      {isListOpenLayout && renderSearchLayout}
      {/** Газрын зурагнаас сонгох хайлт */}
      {isMapActionOpen && (
        <div className='fixed bottom-2 w-full items-center flex justify-between z-40 text-white'>
          <div
            className='bg-black p-6 ml-5 rounded-2xl'
            onClick={() => onLocationChoose()}
          >
            <span>Цуцлах</span>
          </div>
          <div
            className='bg-black p-6 mr-5 rounded-2xl'
            onClick={() => onLocationChoose()}
          >
            <span className='cursor-pointer'>Сонгох</span>
          </div>
        </div>
      )}
      {(isProductShow || isSearchHidden) && <SubProduct />}
    </Fragment>
  );
};

export default SearchLayout;
