'use client';
import { useSuspenseQuery } from '@apollo/client';
import { getActiveTrip, getImapTile } from 'src/graphql/queries';
import TripLayout from '../components/trip';
import { useLocation } from 'react-router-dom';

const TripContainer = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token') || '';

  const { data: imapTile }: any = useSuspenseQuery(getImapTile, {});
  const { get_imap_tile } = imapTile || {};
  const { url } = get_imap_tile || {};

  const { data }: any = useSuspenseQuery(getActiveTrip);
  const { get_trip } = data || {};
  const { trip, success } = get_trip || {};
  if (!success) {
    window.location.href = `/?token=${token}`;
  }
  return <TripLayout trip={trip} url={url} token={token} />;
};

export default TripContainer;
