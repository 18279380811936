import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Props = {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  formAutoClick: (isClick: boolean) => void;
};

const DialogComponent = (props: Props) => {
  const { isShow, setIsShow, formAutoClick } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-40'
        initialFocus={cancelButtonRef}
        onClose={setIsShow}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-black px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-white'
                    >
                      Оргил ачааллын нэмэгдэл
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-yellow-500'>
                        Хэрэглэгч та манай оргил ачааллын үеэр захиалга хийх гэж
                        байгаа тул дуудлагын нэмэгдэл тооцох болохыг анхаарна уу
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 flex items-center justify-center gap-4'>
                  <button
                    type='button'
                    className=' w-full bg-transparent px-3 py-2 text-sm font-semibold text-white shadow-sm'
                    onClick={() => formAutoClick(false)}
                  >
                    Болих
                  </button>
                  <button
                    type='button'
                    className='w-full rounded-xl bg-white px-3 py-2 text-sm font-bold text-black shadow-sm'
                    onClick={() => formAutoClick(true)}
                    ref={cancelButtonRef}
                  >
                    Зөвшөөрөх
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default DialogComponent;
