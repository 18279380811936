import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';

const getCookie = (key: string) =>
  (new RegExp((key || '=') + '=(.*?); ', 'gm').exec(document.cookie + '; ') || [
    '',
    null
  ])[1];

const httpLink = new HttpLink({
  uri: 'https://dev-graphql.1950.mn/graphql'
});

// authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhhM2FhNzVhLTc5OGEtNGE3Ny05ZGIxLWYyZTIzOTU4YmJkOSIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwMzU3MjE2OH0.LuFtY3ydhu9AwGeRjhRfhxvWCpeY5zMeu2nbP8FZhYI'
// authorization_user: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImRjNWNjMmY4LWRkMTUtNDc2MC1iY2NhLTc5YjE0YzNlZGJjYSIsInJvbGUiOiJkcml2ZXIiLCJpYXQiOjE3MDY1OTc4Nzl9.pqxS41-shl8Ur6qMfaOBsGtfvYGFwZx8xxP-_q98ij0'
const authLink = setContext(() => {
  return {
    headers: {
      authorization: getCookie('auth-token') || ''
    }
  };
});

const link = authLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});

if ('development' === process.env.NODE_ENV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export default client;
