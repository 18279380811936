/* eslint-disable react-hooks/exhaustive-deps */
import { Map, Marker } from 'maplibre-gl';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSocket } from 'src/contexts/socket.context';
import { ITrip } from 'src/interfaces/trip.interface';
import { UserFilled, Phone, Time2 } from 'src/theme/icons';
import { SOCKET_EMIT, SOCKET_ON } from 'src/types/socket.type';
import Loading from 'src/modules/commons/loading';
import { useNavigate } from 'react-router-dom';
import CancelTripDialog from 'src/modules/commons/cancel-trip-dialog';
import { useLazyQuery } from '@apollo/client';
import { getStrings } from 'src/graphql/queries';
import { IStrings, STRINGS_TYPE } from 'src/interfaces/strings.interface';
import CarComingDialog from 'src/modules/commons/car-coming-dialog';

type Props = {
  url: string;
  trip: ITrip | null;
  token: string;
};
// let getPosition: Marker | null = null;

const TripLayout = (props: Props) => {
  const { url, token, trip } = props;
  const { driver, booking, vehicle } = trip || {};
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [mapInstance, setMapInstance] = useState<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [getPoisition, setPosition] = useState<Marker | null>(null);
  const [isCancelTrip, setCancelTrip] = useState(false);
  const [isDriverComing, setDriverComing] = useState(false);
  const socket = useSocket();
  const [carSpeed, setCarSpeed] = useState(0);

  const [getStringQuery] = useLazyQuery(getStrings);
  const [strings, setStrings] = useState<Array<IStrings>>([]);
  const renderIcon = () => {
    return (
      document.getElementById('sub_product') || document.createElement('div')
    );
  };

  const onChangeStringType = async (type: STRINGS_TYPE) => {
    const { data, loading } = await getStringQuery({
      variables: {
        type,
        page: 1,
        limit: 1000
      }
    });

    const { get_strings } = data || {};
    const { strings } = get_strings || {};
    setStrings(strings);
    if (!loading) {
      if (type === STRINGS_TYPE.DRIVER_ARRIVED) {
        setDriverComing(true);
      }
      if (type === STRINGS_TYPE.USER_CANCEL) {
        setCancelTrip(true);
      }
    }
  };
  useEffect(() => {
    if (!trip) {
      navigate(`/?token=${token}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  useEffect(() => {
    if (mapContainerRef?.current) {
      const initializeMap = () => {
        const map = new Map({
          container: mapContainerRef?.current || '',
          style: url,
          zoom: 15,
          maxZoom: 17,
          minZoom: 12,
          antialias: true,
          center: [106.92722659999998, 47.9132286],
          interactive: false
        });

        setMapInstance(map);
        return () => {
          if (map) {
            map.remove();
          }
        };
      };

      if (mapInstance) {
        socket.emit(SOCKET_EMIT.JOIN_TRIP, {
          tripId: trip?.id || '',
          userId: trip?.user?.id || ''
        });
        //socket
        socket.on(SOCKET_ON.TRACKING, (docs: any) => {
          const { latitude, longitude, rotate, speed } = docs;
          if (!getPoisition) {
            const marker = new Marker({
              anchor: 'center',
              element: renderIcon(),
              scale: 20,
              draggable: false
            })
              .setLngLat([longitude, latitude])
              .setRotation(rotate)
              .addTo(mapInstance);
            setPosition(marker);
          } else {
            getPoisition?.setLngLat([longitude, latitude]);
            getPoisition?.setRotation(rotate);
          }

          setCarSpeed(speed);
          console.log(getPoisition, 'getPoisition');
        });

        socket.on(SOCKET_ON.TRIP_DRAW_ROAD, (docs: any) => {
          const { speed, locations, rotate } = docs;
          setCarSpeed(speed);
          if (locations.length) {
            if (!getPoisition) {
              const marker = new Marker({
                element: renderIcon(),
                scale: 20,
                draggable: false
              })
                .setLngLat([
                  locations[locations.length - 1].longitude,
                  locations[locations.length - 1].latitude
                ])
                .setRotation(rotate)
                .addTo(mapInstance);
              setPosition(marker);
            } else {
              getPoisition?.setLngLat([
                locations[locations.length - 1].longitude,
                locations[locations.length - 1].latitude
              ]);

              getPoisition?.setRotation(rotate);
            }
          }
          console.log(speed, 'speed');
          console.log(getPoisition?.getLngLat(), 'getPosition_getlnglat');
        });

        socket.on(SOCKET_ON.DRIVER_CANCEL_TRIP, (docs: any) => {
          const { message } = docs;
          if (message) {
            enqueueSnackbar(message, {
              variant: 'warning'
            });
          }
          window.location.href = '/';
        });

        socket?.on(SOCKET_ON.DRIVER_ARRIVED, (asgs: any) => {
          onChangeStringType(STRINGS_TYPE.DRIVER_ARRIVED);
          console.log(JSON.stringify(asgs), 'DRIVER_ARRIVED');
        });
      } else {
        initializeMap();
      }
    }
  }, [
    enqueueSnackbar,
    mapInstance,
    socket,
    url,
    getPoisition,
    onChangeStringType
  ]);

  const onCancelTrip = (message: string) => {
    setCancelTrip(false);
    socket.emit(SOCKET_EMIT.CANCEL_TRIP, {
      tripId: trip?.id || '',
      message: message || 'Цуцаллаа',
      latitude: 0,
      longitude: 0
    });
    window.location.href = `/?token=${token}`;
  };

  const onDriverComing = (message: string) => {
    socket?.emit(SOCKET_EMIT.TRIP_SEND_MESSAGE, {
      message,
      tripId: trip?.id,
      userId: trip?.user?.id,
      firstname: trip?.user?.firstname || '',
      avatar: trip?.user?.avatar
    });
    setDriverComing(false);
    console.log(message, 'onDriverComing_mesage');
  };

  return (
    <Suspense fallback={<Loading />}>
      <div className='relative h-full w-full'>
        <div className='z-10'>
          <div ref={mapContainerRef} className='h-[100vh] w-full map z-[1]' />
          <div
            className='flex-col items-center transition-all ease-in-out'
            id='sub_product'
          >
            <img
              src={trip?.booking?.subProduct?.marker}
              alt=''
              className='w-4 h-10 z-40'
            />
          </div>
        </div>
        <div className='fixed bottom-0 bg-black z-20 text-white w-full p-4'>
          <div className='grid grid-cols-4 gap-4 mb-5'>
            <div className='col-span-3'>
              <div className='flex items-center'>
                <div
                  className={`flex border-2 rounded-full items-center ${
                    trip?.driver?.avatar ? 'p-1' : 'p-2'
                  } bg-zinc-800 shadow-2xl shadow-white border-black mr-3`}
                >
                  {trip?.driver?.avatar ? (
                    <img
                      className='inline-block h-8 w-12 rounded-full'
                      src={trip?.driver?.avatar}
                      alt='driver'
                    />
                  ) : (
                    <UserFilled className='stroke-white fill-white' />
                  )}
                </div>
                <div className='flex flex-col'>
                  <h6 className='mb-1'>{driver?.firstname || ''}</h6>
                  <span className='text-zinc-400 text-sm leading-4'>
                    Жолооч таны дуудлагыг {trip?.arriveMinut || 5} минутаар
                    авсан байна
                  </span>
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex justify-end'>
                <a
                  className='flex items-center border border-white/35 rounded-full p-4'
                  href={`tel:${driver?.phonePrefix}${driver?.phoneNumber}`}
                >
                  <Phone className='stroke-white/85' />
                </a>
              </div>
            </div>
          </div>
          {/** car_detail */}
          <div className='flex flex-wrap items-center justify-between flex-row bg-zinc-700 p-4 w-full rounded-xl mb-5'>
            <div className='flex items-center gap-2'>
              <div
                className={`w-20 h-20 bg-cover bg-no-repeat`}
                style={{
                  backgroundImage: `url('${booking?.subProduct?.image}')`
                }}
              />
              <div className='text-white/85 text-sm'>
                <p className='border-2 rounded-xl border-white/55 py-1 px-3 inline-block mb-1 font-medium uppercase'>
                  {vehicle?.licencePlate || ''}
                </p>
                <div className='flex gap-2'>
                  <span className=''>{vehicle?.color || ''}</span>
                  <span className='uppercase'>
                    {vehicle?.vehicleModel?.name || ''}
                  </span>
                </div>
              </div>
            </div>
            <div className='flex flex-col flex-wrap items-center'>
              <span>
                <Time2 className='stroke-white/85  w-5 h-5 mb-2' />
              </span>
              <span className='text-sm'>{carSpeed} км/ц</span>
            </div>
          </div>
          <div
            className='bg-white w-full p-3 rounded-2xl text-center mb-3'
            onClick={() => onChangeStringType(STRINGS_TYPE.USER_CANCEL)}
          >
            <button className='text-red-700 font-bold text-md'>Цуцлах</button>
          </div>
        </div>
      </div>

      <CancelTripDialog
        strings={strings}
        onCancelTrip={onCancelTrip}
        isOpen={isCancelTrip}
        setOpen={setCancelTrip}
      />
      <CarComingDialog
        strings={strings}
        isOpen={isDriverComing}
        setOpen={setDriverComing}
        onTripChat={onDriverComing}
      />
    </Suspense>
  );
};

export default TripLayout;
