import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './modules/layout/containers/main';
import HomeContainer from './modules/home/containers/home';
import TripContainer from './modules/trip/containers/trip';
import { Suspense } from 'react';
import Loading from './modules/commons/loading';
import InvoiceContainer from './modules/invoice/containers/invoice';

const Routers = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route element={<HomeContainer />} path='/' />
            <Route element={<TripContainer />} path='/trip' />
            <Route element={<InvoiceContainer />} path='/invoice' />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
