export interface IMapConfig {
  longitude: number;
  latitude: number;
  zoom: number;
  maxZoom: number;
  minZoom: number;
}

export enum LOCATION_TYPES {
  SOURCE = 'source',
  DEST = 'dest'
}

export interface IMapSearchAddress {
  name: string;
  longitude: number;
  latitude: number;
  id: string;
  address: string;
}

export enum SEARCH_TYPE {
  YES = 'yes',
  NO = 'no'
}
