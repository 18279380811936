import { useSuspenseQuery } from '@apollo/client';
import HomeLayout from '../components/home';
import { getActiveTrip } from 'src/graphql/queries';

const HomeContainer = () => {
  const { data }: any = useSuspenseQuery(getActiveTrip);
  const { get_trip } = data || {};
  const { trip } = get_trip || {};

  return <HomeLayout trip={trip} />;
};

export default HomeContainer;
