const InvoiceLayout = () => {
  return (
    <div className='h-svh w-full bg-black relative p-5'>
      <div className='flex-col flex-wrap mt-4'>
        <div className='border w-full border-1 rounded-xl border-white/40 items-center flex flex-col'>
          <div className='text-white font-semibold mt-3'>
            <h4 className='mb-2 text-md text-center'>Нийт төлбөр</h4>
            <div className='bg-zinc-700 p-5 rounded-xl mb-4'>
              <span className='text-xl'>4,500₮</span>
            </div>
          </div>
          <div className='text-white w-full px-4 mb-3'>
            <div className='flex justify-between'>
              <span>Аялалын урт</span>
              <span>0.50 км</span>
            </div>
            <div className='flex justify-between'>
              <span>Аялалын хугацаа</span>
              <span>00:39сек</span>
            </div>
          </div>
          <div className='border-t border-1 border-white/25 w-full text-center py-4'>
            <span className='text-white font-semibold text-md'>
              Төлбөрийн дэлгэрэнгүй
            </span>
          </div>
        </div>
      </div>
      {/** QPAY CHECK */}
      <button className='p-4 bg-white text-black fixed  m-auto left-0 right-0 bottom-4 w-11/12 rounded-2xl font-bold'>
        Qpay төлсөн
      </button>
    </div>
  );
};

export default InvoiceLayout;
