import { gql } from '@apollo/client';

export const createBooking = gql`
  mutation create_booking(
    $sub_product_id: ID!
    $sourceAddress: String!
    $destAddress: String
    $sourceLatitude: Float!
    $sourceLongitude: Float!
    $destLatitude: Float
    $destLongitude: Float
    $tip: Float
    $isHandiCapAccessibility: Boolean
    $additionalFields: [SubProductAdditionalField]
    $notes: [String]
  ) {
    create_booking(
      sub_product_id: $sub_product_id
      platform: web
      sourceAddress: $sourceAddress
      destAddress: $destAddress
      sourceLatitude: $sourceLatitude
      sourceLongitude: $sourceLongitude
      destLatitude: $destLatitude
      destLongitude: $destLongitude
      tip: $tip
      isHandiCapAccessibility: $isHandiCapAccessibility
      additionalFields: $additionalFields
      type: now
      notes: $notes
    ) {
      message
      success
      booking_id
    }
  }
`;

export const createUserGolomt = gql`
  mutation createUserGolomt($token: String!) {
    create_user_golomt(token: $token) {
      message
      success
      token
    }
  }
`;
