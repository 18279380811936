import { useEffect } from 'react';
import MapSection from './map';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { createUserGolomt } from 'src/graphql/mutations';
import Loading from 'src/modules/commons/loading';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

type Props = {
  trip: any;
};

const HomeWrap = (props: Props) => {
  const { trip } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [cookie, setCookie] = useCookies(['auth-token']);
  console.table(cookie);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const golomtToken = query.get('token') || null;

  const [golomtTokenCheck, { loading }] = useMutation(createUserGolomt, {
    onCompleted(data) {
      const { create_user_golomt } = data || {};
      const { message, success, token } = create_user_golomt || {};
      if (!success) {
        return enqueueSnackbar(message, {
          variant: 'warning'
        });
      }

      const oneDay = 1 * 24 * 3600 * 1000;
      const cookieOptions: any = {
        expires: new Date(Date.now() + oneDay),
        maxAge: oneDay
      };
      setCookie('auth-token', token, cookieOptions);
      if (trip) {
        window.location.href = `/trip?token=${golomtToken}`;
      }
    }
  });

  useEffect(() => {
    if (golomtToken) {
      golomtTokenCheck({
        variables: { token: golomtToken }
      });
    } else {
      window.location.href = '/404';
    }
  }, [golomtTokenCheck, golomtToken, trip]);

  console.log(golomtToken, 'token');

  if (loading) {
    return <Loading text='Холбогдож байна та түр хүлээнэ үү' />;
  }

  return <MapSection golomtToken={golomtToken || ''} />;
};

export default HomeWrap;
