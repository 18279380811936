export enum STRINGS_TYPE {
  DRIVER_CANCEL = 'driver_cancel',
  USER_CANCEL = 'user_cancel',
  DRIVER_ARRIVED = 'driver_arrived',
  USER_CHAT = 'user_chat',
  DRIVER_CHAT = 'driver_chat',
  CALL_MINUTE = 'call_minute',
  DRIVER_WALLET_CHARGE = 'driver_wallet_charge'
}

export enum TRIP_PROCESS_STATUS {
  PRESTART = 'prestart',
  CANCELED = 'canceled',
  PAUSED = 'paused',
  WAITING = 'waiting',
  PROCESSING = 'processing',
  PAYMENT_PENDING = 'payment_pending',
  END = 'end'
}

export interface IStrings {
  id: string;
  name: string;
  type: STRINGS_TYPE;
  createdAt: Date;
  updatedAt: Date;
}
