import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IStrings } from 'src/interfaces/strings.interface';

type Props = {
  strings: Array<IStrings>;
  onCancelTrip: (message: string) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
};
const CancelTripDialog = (props: Props) => {
  const { onCancelTrip, setOpen, isOpen, strings } = props;
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-40'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500/20 bg-opacity-40 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-y-1/4 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-2xl bg-zinc-800 px-8 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-white mb-4'
                    >
                      Та цуцалсан төрлөө сонгоно уу
                    </Dialog.Title>
                    <div className='mt-2 flex-col'>
                      {(strings || []).map((stringType: IStrings) => (
                        <div
                          className='border border-1 border-white/55 rounded-2xl p-3 mb-3'
                          key={stringType.id}
                          onClick={() => onCancelTrip(stringType.name)}
                        >
                          <span className='text-white'>{stringType.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CancelTripDialog;
