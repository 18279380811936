import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client/react';
import reportWebVitals from './reportWebVitals';

import './index.css';
import client from './lib/apollo-client';
import Routers from './route';
import SnackbarProvider from './lib/snackbar-provider';
import { SocketProvider } from './contexts/socket.context';
import { ProductFormProvider } from './contexts/product.form.context';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <SnackbarProvider>
      <ProductFormProvider>
        <SocketProvider>
          <Routers />
        </SocketProvider>
      </ProductFormProvider>
    </SnackbarProvider>
  </ApolloProvider>
);

reportWebVitals();
