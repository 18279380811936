export const additionalFieldModel = `
  id
  dataType
  isRequired
  name
  options
  orderBy
  sub_product_id
`;

export const priceFieldModel = `
  createdAt
  id
  name
  sub_product_id
  type
  updatedAt
  value
`;

export const subProductModel = `
  artBoard
  bonusPercent
  createdAt
  description
  feePercent
  id
  image
  isNewWindowAdditional
  mainPrice
  marker
  name
  orderBy
  product_id
  status
  tips
  updatedAt  
  prices {
    ${priceFieldModel}
  }
  additional_fields {
    ${additionalFieldModel}
  }
`;

export const productModel = `
  id
  name
  type
  description
  image
  orderBy
  sub_products {
    ${subProductModel}
  }
`;
